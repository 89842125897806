import { Typography, TypographyProps } from "@mui/material";
import React from "react";

export interface ResultProps {
  adornment?: React.ReactNode | string | number;
  title?: string;
  message?: string | React.ReactNode;
  center?: boolean;
}

const adornment = (value: ResultProps["adornment"]) => {
  if (typeof value === "string" || typeof value === "number") {
    return (
      <Typography
        color={"primary"}
        className="text-4xl font-extrabold sm:text-5xl"
      >
        {value}
      </Typography>
    );
  }

  return value;
};

const title = (value: ResultProps["title"]) => {
  return (
    <Typography
      component="h2"
      className="text-2xl font-bold tracking-tight sm:text-3xl"
    >
      {value}
    </Typography>
  );
};

const message = (value: ResultProps["message"]) => {
  const isString = typeof value === "string";
  if (isString) {
    return (
      <Typography
        className="mt-1 text-base text-gray-500"
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    );
  }

  return value;
};

export function Result(props: ResultProps) {
  return (
    <main className="sm:flex">
      {props.adornment && (
        <div
          className="sm:border-r sm:border-gray-200 sm:pr-6 sm:mr-6"
          data-cy="result-adornment"
        >
          {adornment(props.adornment)}
        </div>
      )}
      <div
        className={props.center ? "text-center" : ""}
        data-cy="result-content"
      >
        {title(props.title)}
        {message(props.message)}
      </div>
    </main>
  );
}
