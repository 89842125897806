import { Result } from "@kenai/core";
import { Typography } from "@mui/material";
import Head from "next/head";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  notFound: {
    defaultMessage: "The page you are looking for isn’t here",
    id: "uy5741",
  },
  authorization: {
    defaultMessage: "Authorization required",
    id: "YE+GBJ",
  },
  internal: {
    defaultMessage: "Internal Server Error",
    id: "cTD1CD",
  },
});

interface ErrorPageProps {
  title?: string;
  message: string;
  statusCode?: number;
}

const ErrorPage = (props: ErrorPageProps) => {
  const intl = useIntl();

  const isNotFoundError = props.statusCode?.toString() === "404";
  const isAuthorizationError = props.statusCode?.toString()?.startsWith("4");

  const errorTitle = props.title
    ? props.title
    : isNotFoundError
    ? intl.formatMessage(messages.notFound)
    : isAuthorizationError
    ? intl.formatMessage(messages.authorization)
    : intl.formatMessage(messages.internal);

  return (
    <>
      <Head>
        <title>Error: {errorTitle}</title>
      </Head>
      <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 grid place-items-center lg:px-8">
        <div className="mx-auto max-w-2xl w-full">
          <Result
            adornment={props.statusCode}
            title={errorTitle}
            message={props.message}
          />
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
